import React from 'react';
import Navbar from './Navbar';


const AboutUs = () => {
    return (
        <>
            <Navbar/>
            <div className="flex items-center h-screen px-4 mt-6">
                <div className="h-3/5">
                    <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:6xl font-bold mb-4 uppercase flex gap-2 md:gap-4">About <p className='text-[#3552d1]'>Us</p></h1>
                    <div className='text-xs md:text-lg'>
                    <p className="mb-4 md:mb-8 font-medium">
                        Welcome to Hookstep Influencer! Our mission is to make it easy for creators to connect and collaborate with each other and with brands. We offer a range of influencer marketing solutions to help brands grow their audience and reach their target customers through authentic, engaging content.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                        We know that social media is all about building connections and relationships, but we also know that it can be hard to find the right people to collaborate with. That's why we created this app - to bring creators together and help them create amazing content that resonates with their audience.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                        Our team is made up of social media experts, tech enthusiasts, and entrepreneurs who are passionate about helping creators and brands succeed. We believe that everyone has something unique and valuable to offer, and we want to help them share it with the world.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                    For brands, we offer a range of influencer marketing solutions, including:
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                    Influencer discovery: Our app makes it easy to find the right influencers for your brand, based on factors like audience demographics, engagement rates, and content style.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                    Collaborations: We facilitate collaborations between influencers and brands, from sponsored posts to product placements and more.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                    Campaign management: We handle all the logistics of influencer campaigns, from setting objectives and creating content briefs to tracking metrics and reporting on results.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                    We're constantly working to improve the app and make it more user-friendly, so if you have any feedback or suggestions, please don't hesitate to reach out to us.
                    </p>
                    <p className="mb-4 md:mb-8 font-medium">
                    Thank you for choosing our app, and we look forward to helping you grow your brand through influencer marketing!
                    </p>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default AboutUs;
