import React from 'react';
import Navbar from './Navbar';

const Privacypolicy = () => {
  return (
    <>
      <Navbar />
      <div className="flex items-center px-4 min-h-screen">
        <div className="overflow-y-auto h-[70vh]">
          <h1 className="text-xl  md:text-2xl lg:text-4xl font-bold mb-6">PRIVACY POLICY</h1>
          <p className="text-sm text-gray-500 mb-4">Last updated March 07, 2023</p>
          <p className="text-sm md:text-lg mb-4">
            We take your privacy seriously and are committed to protecting your personal information. This privacy policy ("Policy") describes how we collect, use, and share information collected through our app ("App").
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">1. Information We Collect</h2>
          <p className="text-sm md:text-lg mb-4">
            We may collect the following types of information when you use the App:
          </p>
          <ul className="list-disc list-inside mb-6 text-left">
            <li className="mb-2 text-sm md:text-lg">Personal Information: We may collect personal information such as your name, email address, and payment information when you create an account or make a payment through the App.</li>
            <li className="mb-2 text-sm md:text-lg">Usage Information: We may collect information about your use of the App, such as the pages you visit, the features you use, and the collaborations you participate in.</li>
            <li className="mb-2 text-sm md:text-lg">Device Information: We may collect information about the device you use to access the App, such as the type of device, operating system, and browser.</li>
          </ul>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">2. How We Use Your Information</h2>
          <p className="text-sm md:text-lg mb-4">
            We may use the information we collect for the following purposes:
          </p>
          <ul className="list-disc list-inside mb-6 text-left">
            <li className="mb-2 text-sm md:text-lg">To provide and improve the App;</li>
            <li className="mb-2 text-sm md:text-lg">To facilitate collaborations between users;</li>
            <li className="mb-2 text-sm md:text-lg">To communicate with you about the App and your account;</li>
            <li className="mb-2 text-sm md:text-lg">To process payments and transactions;</li>
            <li className="mb-2 text-sm md:text-lg">To analyze usage and improve the App; and</li>
            <li className="mb-2 text-sm md:text-lg">To comply with legal requirements.</li>
          </ul>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">3. Information Sharing</h2>
          <p className="text-sm md:text-lg mb-4">
            We may share your information with the following types of third parties:
          </p>
          <ul className="list-disc list-inside mb-6 text-left">
            <li className="mb-2 text-sm md:text-lg">Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing and customer support.</li>
            <li className="mb-2 text-sm md:text-lg">Other Users: We may share information about your collaborations with other users of the App.</li>
            <li className="mb-2 text-sm md:text-lg">Legal and Safety: We may disclose your information in response to a legal request or if we believe that it is necessary to protect our rights or the safety of our users.</li>
          </ul>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">4. Data Security</h2>
          <p className="text-sm md:text-lg mb-4">
            We take appropriate measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">5. Children's Privacy</h2>
          <p className="text-sm md:text-lg mb-4">
            The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">6. Your Rights</h2>
          <p className="text-sm md:text-lg mb-4">
            You may have certain rights with respect to your personal information, including the right to access, correct, or delete your information. To exercise these rights, please contact us at support@bolinfluencer.com.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">7. Changes to the Policy</h2>
          <p className="text-sm md:text-lg mb-4">
            We may modify this Policy at any time, with or without notice. Your continued use of the App after any such changes constitutes your acceptance of the modified Policy.
          </p>

          <p className="text-sm md:text-lg mb-8">
            If you have any questions or concerns about this Policy, please contact us at support@bolinfluencer.com.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacypolicy;
