import React from 'react'
import logo from '../Assets/Images/logo.png';
// import icon1 from '../Assets/Images/facebook.png'
// import icon2 from '../Assets/Images/instagram.png'
// import icon3 from '../Assets/Images/linkedin (1).png'
// import icon4 from '../Assets/Images/youtube.png'
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa6';

const Navbar = () => {
  return (
    <>
         
            <nav className="px-8 pt-6 fixed top-0 w-full z-10">
                <div className="flex justify-between items-center">
                    {/* Logo and text */}
                    <Link to='/'>
                    <div className="text-white text-2xl font-bold flex justify-center items-center gap-4">
                        <img src={logo} alt="" className='md:w-20 w-14' />
                        <p className='uppercase text-[#3552d1] hidden md:flex'>Hookstep</p>
                    </div>
                    </Link>

                    {/* Download button and icons */}
                    <div className="md:flex items-center gap-10">
                        {/* Download button */}
                        <div className="w-full">
                            <button
                                className="uppercase bg-[#3552d1] text-white px-4 py-1 md:px-6 md:py-3 text-xs md:text-lg font-bold flex items-center w-full justify-center"
                                
                            >
                                <p>Download</p>
                            </button>
                        </div>

                        {/* Icons */}
                        <div className="md:flex gap- hidden items-center">
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                {/* <img src={icon1} alt="" className="cursor-pointer hvr-grow w-12" /> */}
                                <FaFacebook className='text-3xl hvr-grow'/>
                            </div>
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                {/* <img src={icon2} alt="" className="cursor-pointer hvr-grow w-12" /> */}
                                <FaInstagram className='text-3xl hvr-grow'/>
                            </div>
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                {/* <img src={icon3} alt="" className="cursor-pointer hvr-grow w-12" /> */}
                                <FaLinkedinIn className='text-3xl hvr-grow'/>
                            </div>
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                {/* <img src={icon4} alt="" className="cursor-pointer hvr-grow w-12" /> */}
                                <FaYoutube className='text-3xl hvr-grow'/>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
    </>
  )
}

export default Navbar
