import React from 'react';
import Navbar from './Navbar';

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <div className="flex items-center min-h-screen px-4 ">
        <div className="w-full h-[70vh] overflow-y-auto">
          <h1 className="lg:text-2xl md:text-xl text-xl font-bold mb-6 ">TERMS AND CONDITIONS</h1>
          <p className="text-sm text-gray-500 mb-4 ">Last updated March 07, 2023</p>
          
          <p className="text-sm md:text-lg mb-4">
            Welcome to Hookstep Influencer app! These terms and conditions ("Terms") apply to your use of our app ("App"). By using our App, you agree to be bound by these Terms, so please read them carefully.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">1. Account Registration</h2>
          <p className="text-sm md:text-lg mb-4">
            To use certain features of the App, you may be required to create an account. When creating an account, you must provide accurate and complete information, and you must keep your account information up-to-date. You are responsible for maintaining the confidentiality of your account login credentials and for any activity that occurs under your account.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">2. Use of the App</h2>
          <p className="text-sm md:text-lg mb-4">
            The Hookstep Influencer App is intended to be used for the purpose of connecting creators with one another or with brands for collaboration opportunities. You agree to use the App only for lawful purposes and in accordance with these Terms. You agree not to use the App to:
          </p>
          <ul className="list-disc list-inside mb-6 text-left">
            <li className="mb-2 text-sm md:text-lg">violate any applicable law or regulation;</li>
            <li className="mb-2 text-sm md:text-lg">infringe on the intellectual property rights of others;</li>
            <li className="mb-2 text-sm md:text-lg">harass, bully, or defame others;</li>
            <li className="mb-2 text-sm md:text-lg">engage in fraudulent or deceptive activity;</li>
            <li className="mb-2 text-sm md:text-lg">upload or transmit viruses or other harmful code;</li>
            <li className="mb-2 text-sm md:text-lg">interfere with the security or functionality of the App;</li>
            <li className="mb-2 text-sm md:text-lg">use the App to collect personal information from others without their consent; or</li>
            <li className="mb-2 text-sm md:text-lg">engage in any other activity that is prohibited by law or these Terms.</li>
          </ul>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">3. Eligibility</h2>
          <p className="text-sm md:text-lg mb-4">
            You must be at least 18 years old and have the legal capacity to enter into contracts to use our app. If you are using the app on behalf of a business, you must have the authority to bind the business to these terms and conditions.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">4. Collaborations</h2>
          <p className="text-sm md:text-lg mb-4">
            The Hookstep Influencer App is designed to facilitate collaborations between creators. By using the App to participate in a collaboration, you agree to the terms of the collaboration as agreed upon with the other party or parties involved. You are responsible for ensuring that you have the necessary rights and permissions to participate in the collaboration and for delivering work that meets the expectations of the other party or parties involved.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">5. Payments and Fees</h2>
          <p className="text-sm md:text-lg mb-4">
            The App may charge fees for certain services or transactions. By using the App, you agree to pay any applicable fees and to provide accurate billing information. The App may deduct fees from payments made through the App or may invoice you for fees owed. The App reserves the right to modify its fees and payment policies at any time.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">6. Intellectual Property</h2>
          <p className="text-sm md:text-lg mb-4">
            All content and materials on the App, including but not limited to text, images, logos, and software, are the property of the App or its licensors and are protected by intellectual property laws. You may not copy, reproduce, distribute, or display any content from the App without the express written consent of the App.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">7. Termination</h2>
          <p className="text-sm md:text-lg mb-4">
            The Hookstep Influencer App may terminate your account or your access to the App at any time, for any reason or no reason, with or without notice. Upon termination, you must cease all use of the App and delete any content or materials downloaded from the App.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">8. Disclaimer of Warranties</h2>
          <p className="text-sm md:text-lg mb-4">
            The Hookstep Influencer App is provided "as is" and without warranty of any kind, whether express or implied. The App does not warrant that the App will be error-free or uninterrupted, that defects will be corrected, or that the App is free of viruses or other harmful code.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">9. Limitation of Liability</h2>
          <p className="text-sm md:text-lg mb-4">
            In no event shall the Hookstep Influencer App be liable for any damages, whether direct, indirect, incidental, special, or consequential, arising out of or in connection with your use of the App or these Terms, even if the App has been advised of the possibility of such damages.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">10. Governing Law and Dispute Resolution</h2>
          <p className="text-sm md:text-lg mb-4">
            Any dispute arising out of or in connection with these terms and conditions, including any question regarding their existence, validity, or termination, shall be referred to and finally resolved by arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The language of the arbitration shall be English, and the arbitration shall be held in [insert location]. The award of the arbitrator(s) shall be final and binding. Each party shall bear its own costs in the dispute resolution process.
          </p>

          <h2 className="lg:text-2xl md:text-xl text-xl font-bold mb-4">11. Changes to the Terms</h2>
          <p className="text-sm md:text-lg mb-4">
            The App reserves the right to modify these Terms at any time, with or without notice. Your continued use of the App after any such changes constitutes your acceptance of the Terms and conditions.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
