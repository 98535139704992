import React from 'react';
import Navbar from './Navbar';

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="flex items-center  min-h-screen px-4 bg-gray-50">
        <div className="w-full p-8 h-[70vh] overflow-y-auto">
          <h1 className="text-4xl font-bold mb-6">REFUND AND CANCELLATION POLICY</h1>
          <p className="text-sm text-gray-500 mb-4">Last updated May 15, 2023</p>

          <p className="text-lg mb-4">
            We want all of our users to be completely satisfied with their experience using our app. If, for any reason, you are not satisfied with the services provided by our app, we offer a refund policy as follows:
          </p>

          <ul className="list-disc list-inside mb-6 text-left">
            <li className="mb-2 text-lg">
              If a collaboration between two creators or between a brand and a creator is cancelled before any work has been completed, we will provide a full refund of any payments made through our app.
            </li>
            <li className="mb-2 text-lg">
              If a collaboration between two creators or between a brand and a creator is cancelled after work has been started, but before it has been completed, we will provide a partial refund based on the percentage of work that has been completed.
            </li>
            <li className="mb-2 text-lg">
              If a collaboration between two creators or between a brand and a creator is completed but one or both parties are not satisfied with the work, we will review the situation on a case-by-case basis to determine if a refund is appropriate.
            </li>
          </ul>

          <p className="text-lg mb-4">
            To request a refund, please contact our customer support team at 
            <a href="mailto:support@bolinfluencer.com" className="text-blue-500"> support@bolinfluencer.com</a>. 
            Please provide the details of the collaboration and the reason for the refund request. Our team will review the request and respond as soon as possible.
          </p>

          <p className="text-lg mb-4">
            Please note that we reserve the right to refuse any refund request if we believe that it is not valid or if it violates our terms of service. We also reserve the right to modify this refund policy at any time, so we encourage you to check back regularly for updates.
          </p>

          <h2 className="text-2xl font-bold mb-4">Steps to initiate a refund:</h2>

          <h3 className="text-xl font-bold mb-4">For Creator</h3>
          <ol className="list-decimal list-inside mb-6 text-left">
            <li className="mb-2 text-lg">Go to profile, Go to requests then click on the request</li>
            <li className="mb-2 text-lg">Reject the particular user's payment request, it will show a dialog for payment declination, click on yes</li>
            <li className="mb-2 text-lg">It will show a Generate Dispute Dialog, press yes, fill the form, add screenshots, and click submit</li>
            <li className="mb-2 text-lg">It will generate the dispute and show the request in the admin panel.</li>
          </ol>

          <h3 className="text-xl font-bold mb-4">For Collaborator</h3>
          <ol className="list-decimal list-inside mb-6 text-left">
            <li className="mb-2 text-lg">Go to profile, Open drawer and click on my collabs</li>
            <li className="mb-2 text-lg">Click on ongoing and click on the particular request which is rejected</li>
            <li className="mb-2 text-lg">Click on three dots then press on dispute, fill the form, add screenshots, and click submit</li>
            <li className="mb-2 text-lg">It will generate the dispute and show in the admin panel.</li>
          </ol>

          <h3 className="text-2xl font-bold mb-4">Contact us</h3>
          <p className="text-lg mb-4">
            If you have any queries, please contact our support team by emailing us at
            <a href="mailto:support@bolinfluencer.com" className="text-blue-500"> support@bolinfluencer.com</a>. 
            Our admin team will analyze the request and initiate the refund or transfer the amount to the collaborator. When contacting us, please create a dispute to help us process your refund more quickly.
          </p>

          <p className="text-lg mb-4">
            Our support team will review your request and contact you to confirm that your refund has been approved. Please allow up to 7 business days for us to process your refund.
          </p>

          <p className="text-lg mb-4">
            Once your refund has been processed, the funds will be returned to the original payment method you used. It may take several days for the refund to appear in your account, depending on your bank or credit card company's policies.
          </p>

          <p className="text-lg mb-4">
            We want you to be completely satisfied with our app and services. If you have any questions or need further assistance with a refund, don't hesitate to contact our support team.
          </p>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
